.list-button {
  gap: 12.5px;
  margin: 10px auto !important;
  border-radius: 8px !important;
}

.list-button-active {
  background: -webkit-gradient(linear, right top, left top, color-stop(25.78%, #45079a), color-stop(110%, rgba(69, 7, 154, 0.6)));
  background: linear-gradient(270deg, #45079a 25.78%, rgba(69, 7, 154, 0.6) 110%);
}

.list-button-active * {
  color: white !important;
}

.list-icons {
  min-width: unset !important;
}

.expandless-button {
  width: 0.85em !important;
  height: auto !important;
}

.list-collapse {
  background-color: var(--light-purple);
  margin-top: -15px;
  border-radius: 0px 0px 8px 8px;
  color: var(--primary-color) !important;
}

.list-collapse * {
  color: var(--primary-color) !important;
}

.custom-logout {
  padding: 0px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 12.5px;
}

.custom-logout * {
  font-size: 14px;
}

.show-nav-icon {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .show-nav-icon {
    display: block !important;
  }
}
