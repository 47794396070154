:root {
     --text-color: #57584e;
     --hover-blue: #197cd7;
     --pressed-blue: #2147a8;
     --disabled-blue: rgba(25, 124, 215, 0.7);
   
     --deeper-blue: #171832;
     --sky-blue: #26abc7;
     --white: #fff;
     --light-font: #8a92a6;
     --secondary-grey: #f7f7f9;
     --secondary-grey-text: #6f727a;
     --danger: #f32d2d;
     --success: #55bd68;
     --toastify-color-error: blue;
     --carton: #f7f7f9;
     --ash: #6c6e7f;
     // --primary-color: red;
     --primary-color: #45079a;
     --light-purple: #f7f6fd;
     --lighter-purple: rgba(235, 233, 249, 0.6);
   
     --primary-color-black: #232325;
   }
